/*!
 * Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fa-regular-400.609f9c4c.woff2") format("woff2");
}

.far {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
}
/*# sourceMappingURL=index.8b82385d.css.map */
